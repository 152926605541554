import React from "react";

const InvalidPage = () => {
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      backgroundColor: "#f5f5f5",
      fontFamily: "Arial, sans-serif",
    },
    heading: {
      color: "#FFA000",
      fontSize: "2.5rem",
      marginBottom: "20px",
    },
    message: {
      fontSize: "1.2rem",
      textAlign: "center",
      maxWidth: "80%",
      lineHeight: "1.5",
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Invalid Page</h1>
      <p style={styles.message}>
        Oops! The page you're trying to access is invalid or doesn't exist.
      </p>
    </div>
  );
};

export default InvalidPage;
