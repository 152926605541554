import React, { useEffect } from "react";

const PaymentFailure = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = "https://stage.aichanakya.in";
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      backgroundColor: "#fff0f0",
      fontFamily: "Arial, sans-serif",
    },
    heading: {
      color: "#D32F2F",
      fontSize: "2.5rem",
      marginBottom: "20px",
    },
    message: {
      fontSize: "1.2rem",
      textAlign: "center",
      maxWidth: "80%",
      lineHeight: "1.5",
    },
    redirect: {
      marginTop: "20px",
      color: "#666",
      fontSize: "1rem",
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Payment Failed</h1>
      <p style={styles.message}>
        We're sorry, but your payment could not be processed. Please try again
        or contact support if the issue persists.
      </p>
      <p style={styles.redirect}>Redirecting you in 2 seconds...</p>
    </div>
  );
};

export default PaymentFailure;
