import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import PaymentFailure from "./Payment/PaymentFailure";
import PaymentSuccess from "./Payment/PaymentSuccess";
import InvalidPage from "./Payment/InvalidPage";
import "./App.css";

function App() {
  const [allowPaymentPages, setAllowPaymentPages] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const isGatewayRedirect = urlParams.get("gateway_redirect") === "true";

    if (isGatewayRedirect) {
      setAllowPaymentPages(true);
    }
  }, []);

  const ProtectedRoute = ({ children }) => {
    const urlParams = new URLSearchParams(window.location.search);
    const isGatewayRedirect = urlParams.get("gateway_redirect") === "true";

    if (!allowPaymentPages && !isGatewayRedirect) {
      return <Navigate to="/invalid" replace />;
    }
    return children;
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/payment/success"
            element={
              <ProtectedRoute>
                <PaymentSuccess />
              </ProtectedRoute>
            }
          />
          <Route
            path="/payment/failure"
            element={
              <ProtectedRoute>
                <PaymentFailure />
              </ProtectedRoute>
            }
          />
          <Route path="/invalid" element={<InvalidPage />} />
          <Route path="*" element={<InvalidPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
